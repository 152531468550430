<template>
  <div class="flex flex-wrap space-x-4 w-full justify-center pb-2 px-4 text-center text-gray-500 dark:text-white">
    <a @click="goTo('/terms')">Terms</a>
    <a @click="goTo('/privacy')">Privacy</a>
    <a @click="goTo('/data-retention')">Data Retention</a>
    <a @click="goTo('/do-not-sell')">Do Not Sell My Personal Information</a>
    <a @click="goTo('/cookies')">Cookies</a>
    <a @click="goTo('/fair-chance')">Fair Chance</a>
    <a href="mailto:hello@joinhomeplan.com">Contact Us</a>
    <span>
      © 2021-2023 Homeplan.com, Inc
    </span>
  </div>
</template>

<script>
export default {
  methods: {
    goTo(link) {
      this.$router.push(link);
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style scoped>
a {
  @apply cursor-pointer;
}

a:hover {
  @apply text-hp-blue;
}
</style>